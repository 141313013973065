<template>
    <iframe :id="iframe_id" :src="modelerUrl" :width="iframe_width" :height="iframe_height" style="border: 0px;" ></iframe>
</template>

<script>
export default {
    name: 'flowable-modeler',
    props: {
        name: {
            default: ''
        },
        params: {},
        height: {
            type: String,
            required: false,
            default: ''
        },
        width: {
            type: String,
            required: false,
            default: '100%'
        },
    },
    data() {
        let id = new Date().getTime();
        //this.axios.defaults.baseURL + '/flowable-ui/modeler/index.html';
        let modelerUrl = this.axios.defaults.baseURL + '/flowable-ui/modeler/index.html';
        return {
            iframe_id: 'report_iframe_' + id,
            modelerUrl,
            iframe_width: this.width,
            iframe_height: this.height || '100%'
        };
    },
    created() {
    },
    methods: {
    }
};
</script>

<style>

</style>

